<template>
  <div class="expert-result">
    专家评估情况
  </div>
</template>

<script lang="ts">
</script>

<style lang="less" scoped>

</style>
